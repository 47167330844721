<template>
  <div class="product-page">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="product-block-title">
        Образовательная платформа для учителей и специалистов в сфере образования
      </div>
      <div class="product-block-desc">
        Проект <strong>«Smart Ustaz»</strong> – это образовательная платформа в помощь учителям и специалистам сферы
        образования для развития универсальных личностных навыков и компетенций 21 века.
      </div>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/smartustaz-back1.png"
          fit="fit"></el-image>

      <div class="product-block-desc">
        Проект представляет собой платформу для онлайн обучения с контентом в виде текстовых лекций, аудио, видео,
        презентаций, прохождением тестирования для закрепления материала, автоматическим обновлением прогресса,
        получением сертификатов, а также участием в тренингах, опросах и мастер классах.
      </div>

      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/smartustaz-back2.png"
          fit="fit"></el-image>

      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/smartustaz-back3.png"
          fit="fit"></el-image>

      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/smartustaz-back4.png"
          fit="fit"></el-image>


    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Products',
  data() {
    return {
      currentTab: "all",
      skills: [
        'Изучение основ продаж',
        'Формирование УТП',
        'Работа с воронками продаж',
        'Основы построения контакта с клиентом',
        'Навыки самопрезентации',
        'Презентация продукта/услуги',
        'Работа с CRM системой',
        'Работа с системой телефонии',
        'Основы переговоров',
        'Базы работы со скриптами разговоров',
        'Основа работы с холодными звонками',
        'Работа с возражениями',
      ],
      advantages: [
        'Без учителей',
        'Без домашних заданий',
        'Без дедлайнов',
        'Без вебинаров',
      ]
    }
  },
}
</script>
<style>

</style>
